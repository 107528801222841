<div class="skills">
  <br />

  <h3 class="specialTitle">Skills</h3>
  <div class="flex">
    <div class="skill-bars">
      <div class="bar">
        <div class="info">
          <span>Java</span>
        </div>
        <div class="progress-line java">
          <span></span>
        </div>
      </div>
      <div class="bar">
        <div class="info">
          <span>PHP</span>
        </div>
        <div class="progress-line php">
          <span></span>
        </div>
      </div>
      <div class="bar">
        <div class="info">
          <span>Javascript</span>
        </div>
        <div class="progress-line javascript">
          <span></span>
        </div>
      </div>
      <div class="bar">
        <div class="info">
          <span>HTML/CSS</span>
        </div>
        <div class="progress-line html-css">
          <span></span>
        </div>
      </div>
      <div class="bar">
        <div class="info">
          <span>SQL</span>
        </div>
        <div class="progress-line sql">
          <span></span>
        </div>
      </div>
    </div>
    <div class="skill-bars">
      <div class="bar">
        <div class="info">
          <span>Spring</span>
        </div>
        <div class="progress-line spring">
          <span></span>
        </div>
      </div>
      <div class="bar">
        <div class="info">
          <span>Laravel</span>
        </div>
        <div class="progress-line laravel">
          <span></span>
        </div>
      </div>
      <div class="bar">
        <div class="info">
          <span>Node.js</span>
        </div>
        <div class="progress-line nodejs">
          <span></span>
        </div>
      </div>
      <div class="bar">
        <div class="info">
          <span>Angular</span>
        </div>
        <div class="progress-line angular">
          <span></span>
        </div>
      </div>
      <div class="bar">
        <div class="info">
          <span>WordPress</span>
        </div>
        <div class="progress-line wordpress">
          <span></span>
        </div>
      </div>
    </div>
  </div>
</div>
