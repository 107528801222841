<header>
  <nav class="navigation">
    <span
      ><img src="../../assets/LOGO/logo_ayoub_dbr_white.svg" alt="" width="50%"
    /></span>
    <span class="empty"></span>

    <a href="#1" data-index="1" id="viewing-page-1" class="homeItem">Home</a>
    <a href="#2" data-index="2" id="viewing-page-2" class="aboutItem"
      >About me</a
    >
    <a href="#3" data-index="3" id="viewing-page-3" class="projectsItem"
      >Projects</a
    >
    <a href="#4" data-index="4" id="viewing-page-4" class="contactItem"
      >Contact</a
    >
  </nav>
</header>
