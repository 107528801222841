import { Component, OnInit } from '@angular/core';

declare var require: any;

const FileSaver = require('file-saver');

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  downloadPdf(language: String) {
    const pdfUrl = `/assets/cv/${language}.pdf`;
    const pdfName = `AYOUB_DENEUBOURG_CV_${language}`;

    FileSaver.saveAs(pdfUrl, pdfName);
  }
}
