<div>
  <a
    href="{{ item.link }}"
    target="_blank"
    *ngFor="let item of service.socials"
  >
    <img
      width="50px"
      src="../../../assets/socials/type2/{{ item.logo }}"
      alt=""
    />

    <span>
      <h4>{{ item.title }}</h4>
      <h5>{{ item.accountName }}</h5>
    </span>

    <div>
      <img width="50px" src="../../../assets/arrow.svg" alt="" />
    </div>
  </a>
</div>
