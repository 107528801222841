import { Form } from './../models/form';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private formUrl = 'https://ayoub-be-api.herokuapp.com/contact';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    }),
  };

  constructor(private http: HttpClient) {}

  postForm(form: Form): Observable<Form> {
    return this.http.post<Form>(this.formUrl, form, this.httpOptions);
  }
}
