<div class="container">
  <div class="title">
    {{ object["title"] }}
  </div>

  <div class="type">{{ object["type"] }} - {{ object["vak"] }}</div>

  <div class="body" [innerHTML]="object['body']"></div>

  <div class="flex-1"></div>
  <div class="keywords">
    <div class="keyword {{ item }}" *ngFor="let item of object['technologies']">
      {{ item }}
    </div>
  </div>
  <br />
  <h4 class="date">
    <img width="25px" src="../../../assets/date.svg" />
    <span> {{ object["date"] }}</span>
  </h4>
  <h4 class="team">
    <img width="25px" src="../../../assets/group.svg" />
    <span> {{ object["team"] }}</span>
  </h4>

  <br />
  <a
    href="{{ object['link'] }}"
    target="_blank"
    [ngClass]="object['link'] ? '' : 'invisible'"
    class="link"
  >
    Open the website
  </a>
</div>
