<div class="bg"></div>
<div class="bg bg2"></div>
<div class="bg bg3"></div>
<app-header></app-header>

<div id="desktop" class="main">
  <section class="page1">
    <app-home> </app-home>
  </section>

  <section class="page2">
    <app-about></app-about>
  </section>
  <section class="page3">
    <app-projects></app-projects>
  </section>

  <section class="page4">
    <app-contact></app-contact>
  </section>
</div>

<div id="phone" class="main">
  <div class="page1">
    <app-home> </app-home>
  </div>

  <div class="page2">
    <app-info></app-info>
    <app-experience></app-experience>
  </div>
  <app-skills></app-skills>
  <div class="page3">
    <app-projects></app-projects>
  </div>

  <div class="page4">
    <app-contact></app-contact>
  </div>
</div>
