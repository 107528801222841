import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: string = 'portfolios';

  mobile = false;

  constructor() {}
  ngOnInit(): void {
    if (window.screen.width > 1200) {
      // 768px portrait
      console.log('heaae');
      this.mobile = true;
    }
  }
}
