<div class="info">
  <h3 class="specialTitle">About me</h3>
  <div class="moreinfo">
    I'm Ayoub Deneubourg and I am twenty years old. I am full stack developer
    with two years of experience. I am passionate about programming. In my free
    time, I play sports, go out with my friends and create websites. You can
    download my CV
    <a href="javascript:void(0)" (click)="downloadPdf('EN')">here</a>. It is
    also available in other languages such as
    <a href="javascript:void(0)" (click)="downloadPdf('FR')">French</a> or
    <a href="javascript:void(0)" (click)="downloadPdf('NL')">Dutch</a>.
  </div>
</div>
