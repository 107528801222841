<form (submit)="postForm()">
  <h3 class="title">Contact me</h3>
  <label for="name" class="form-label font-bold block text-sm">Name</label>
  <input
    required
    type="text"
    placeholder="Ayoub Deneubourg"
    id="name"
    name="name"
    [(ngModel)]="data.name"
  />
  <label for="mail" class="form-label font-bold block text-sm">Mail</label>
  <input
    required
    type="mail"
    placeholder="deneubourgayoub@gmail.com"
    id="mail"
    name="mail"
    [(ngModel)]="data.mail"
  />
  <label for="message" class="form-label font-bold block text-sm"
    >Message</label
  >
  <textarea
    required
    type="text"
    placeholder="Here your message"
    id="message"
    name="message"
    rows="5"
    [(ngModel)]="data.message"
  ></textarea>

  <button>Contact me</button>
</form>
