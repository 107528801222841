<br />
<div class="mobile">
  <br />
  <br />
</div>
<h3 class="specialTitle">All projects</h3>
<div class="mobile">
  <br />
  <br />
  <br />
  <br />
</div>
<br />
<carousel
  [cellWidth]="300"
  [height]="500"
  [loop]="true"
  [autoplayInterval]="2000"
>
  <div class="carousel-cell" *ngFor="let item of service.projects">
    <app-carousel-item [object]="item"></app-carousel-item>
  </div>
</carousel>
