import { DataServiceService } from 'src/app/services/data-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socials',
  templateUrl: './socials.component.html',
  styleUrls: ['./socials.component.scss'],
})
export class SocialsComponent implements OnInit {
  constructor(public service: DataServiceService) {}

  ngOnInit(): void {}
}
