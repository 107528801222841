import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LittleCirclesComponent } from './animations/little-circles/little-circles.component';
import { BigCircleComponent } from './animations/big-circle/big-circle.component';
import { HomeBackgroundComponent } from './animations/home-background/home-background.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { SkillsComponent } from './components/skills/skills.component';
import { ExperienceComponent } from './components/experience/experience.component';
import { InfoComponent } from './components/info/info.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { CarouselItemComponent } from './components/carousel-item/carousel-item.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FormComponent } from './components/form/form.component';
import { SocialsComponent } from './components/socials/socials.component';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LittleCirclesComponent,
    BigCircleComponent,
    HomeBackgroundComponent,
    HomeComponent,
    AboutComponent,
    SkillsComponent,
    ExperienceComponent,
    InfoComponent,
    CarouselComponent,
    ProjectsComponent,
    CarouselItemComponent,
    ContactComponent,
    FormComponent,
    SocialsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IvyCarouselModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
