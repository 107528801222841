import { FormService } from './../../services/form.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  constructor(private http: HttpClient, private formService: FormService) {}

  ngOnInit(): void {}

  data = {
    name: '',
    mail: '',
    message: '',
  };

  postForm() {
    this.formService.postForm(this.data).subscribe((res) => {
      console.log(res);
    });
  }
}
