<div class="education">
  <br />
  <h3 class="specialTitle">Experience</h3>

  <div class="container">
    <div class="item">
      <h4>Erasmushogeschool Brussel</h4>
      <span>
        <h5>Multimedia & Creative Technologies</h5>
        <h6>2019 - 2022</h6>
      </span>
      <h5>
        Bachelor's degree specializing in website and mobile application
        development. A great mix between conceptual thinking, (web) design and a
        lot of programmation. Programming in different languages and frameworks
        such as Java, Spring, PHP, Laravel, Javascript, Node.JS and much more!
      </h5>
    </div>

    <div class="item">
      <h4>Techify</h4>
      <span>
        <h5>Web developer</h5>
        <h6>2019 - Now</h6>
      </span>
      <h5>
        Offer a digital solution to customers. Mainly a website creation using
        technologies such as Laravel, Java or WordPress.
      </h5>
    </div>
  </div>
</div>
