<div class="home">
  <article class="first">
    <h1>Ayoub Deneubourg</h1>
    <h2>Full Stack Developer</h2>
  </article>

  <article class="second">
    <img src="../../../assets/myself.jpeg" />
  </article>
</div>
