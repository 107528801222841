import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataServiceService {
  projects: Array<Object> = [
    {
      title: "Ladder'Op",
      type: 'Website',
      vak: 'Full Projects 1',
      body: 'Website to convince tutors to support and guide underprivileged children in Belgium. <br> We had three weeks to come up with a concept, design and code the website. We did three one-week sprints using the "SCRUM" method.',
      technologies: ['HTML', 'CSS', 'Javascript'],
      team: 'Alex, Farah, Niels, Cédric, Katherine & Ayoub',
      picture: '',

      link: "http://dtsl.ehb.be/~ayoub.deneubourg/root/Ladder'OP/",
      date: '2019 - 2020, SEMESTER 1',
    },

    {
      title: 'ArtistAdvisor',
      type: 'Website',
      vak: 'Development II',
      body: 'Find your favorite artist and find his five most popular music from Deezer API.',
      technologies: ['HTML', 'CSS', 'Javascript', 'NoSQL'],
      team: 'Individueel Project',
      picture: '',

      link: 'http://dtsl.ehb.be/~ayoub.deneubourg/root/ArtistAdvisor/',
      date: '2019 - 2020, SEMESTER 2',
    },
    {
      title: 'SimonSays',
      type: 'Website',
      vak: ' Physical Computing & Digital Making',
      body: "Website to support the game 'Simon Says' playable with Arduino (with code that we have created) or with the keyboard keys: ",
      technologies: ['HTML', 'CSS', 'Javascript'],
      team: 'Adnane, Youssef, Ilias & Ayoub',
      picture: '',

      link: 'http://dtsl.ehb.be/~ayoub.deneubourg/root/SimonSays%20-%20Game/',
      date: '2019 - 2020, SEMESTER 2',
    },

    {
      title: 'Start2Play',
      type: 'Web Applicatie',
      vak: 'Full Projects 2',
      body: 'Web Application to make the people more playful. <br> We had three weeks to come up with a concept, design and code the website. We did three one-week sprints using the "SCRUM" method.',
      technologies: ['HTML', 'CSS', 'Javascript', 'NoSQL'],
      team: 'Adnane, Youssef, Ilias, Nadia, Hajar & Ayoub ',
      picture: '',

      link: '',
      date: '2019 - 2020, SEMESTER 2',
    },

    {
      title: 'PLAY PA.I.NO',
      type: 'Website',
      vak: 'Full Projects 3',
      body: 'Website for play piano alone, together with a bot (with A.I) or online with your friends. <br> We had three weeks to come up with a concept, design and code the website. We did three one-week sprints using the "SCRUM" method.',
      technologies: ['HTML', 'CSS', 'Javascript'],
      team: 'Adnane, Youssef, Julien, Omar, Soufyan & Ayoub ',
      picture: '',

      link: 'https://play-paino.web.app/',
      date: '2020 - 2021, SEMESTER 1',
    },
    {
      title: 'BestWorkout',
      type: 'Website',
      vak: 'WEB II',
      body: 'Online platform for users to create workouts and upload it for others. The exercises come from an API but users can also create exercises.',
      technologies: ['HTML', 'CSS', 'Javascript', 'NoSQL'],
      team: ' Individueel project',
      picture: '',

      link: 'https://web2-course-project-site-ayoub.herokuapp.com/',
      date: '2020 - 2021, SEMESTER 1',
    },
    {
      title: 'Twitch',
      type: 'Website',
      vak: 'Front-End',
      body: 'Redesigned Twitch and partially redone.',
      technologies: ['Angular', 'Sass'],
      team: ' Individueel project',
      picture: '',

      link: 'https://twitch-front-end.herokuapp.com/',
      date: '2020 - 2021, SEMESTER 2',
    },

    {
      title: 'Examen manager',
      type: 'Website',
      vak: 'Backend',
      body: 'Platform where teachers can manage courses, tests and questions.',
      technologies: ['PHP', 'Laravel', 'Sass', 'SQL'],
      team: ' Individueel project',
      picture: '',

      link: '',
      date: '2020 - 2021, SEMESTER 2',
    },

    {
      title: 'Anonymous app',
      type: 'Hybrid Mobile App',
      vak: 'GROWII',
      body: 'Platform to speak completely anonymously with unknown people.',
      technologies: ['Dart', 'Flutter', 'NoSQL'],
      team: ' Individueel project',
      picture: '',

      link: 'https://www.youtube.com/watch?v=970hewlijUw&feature=youtu.be',
      date: '2020 - 2021, SEMESTER 2',
    },
    {
      title: 'Personality app',
      type: 'Mobile App',
      vak: 'Android',
      body: 'Create a personality test and share your result with other users.',
      technologies: ['Android', 'NoSQL'],
      team: ' Individueel project',
      picture: '',

      link: '',
      date: '2020 - 2021, SEMESTER 2',
    },
    {
      title: 'Portfolio',
      type: 'Website',
      vak: 'Front-End',
      body: 'Little portfolio with information about me and all my projects.',
      technologies: ['React'],
      team: ' Individueel project',
      picture: '',

      link: 'http://ayoubdbr.herokuapp.com/',
      date: '2020 - 2021, SEMESTER 2',
    },
    {
      title: 'Finalshow API',
      type: 'Website',
      vak: 'Full Projects 4',
      body: 'Finalshow API is an web app where backend that contains all the routes for the maintenance of the frontend websites. It also contains two frontends for a student to upload a project and a docent dashboard to vote and nominate a project.',
      technologies: ['Node.js', 'EJS', 'SQL'],
      team: 'Julien, Youssef, Adnane, Soufyan, Chaimae & myself',
      picture: '',

      link: 'https://finalshow.be/',
      date: '2020 - 2021, SEMESTER 2',
    },
    {
      title: 'MyPets',
      type: 'Website',
      vak: 'Front-End',
      body: 'Platform that allows pet owners to get advice about animals more easily.  This project is not finished.',
      technologies: ['PHP', 'Laravel', 'SQL'],
      team: 'Individueel project',
      picture: '',

      link: 'https://laravelanimals.herokuapp.com/',
      date: '2020 - 2021, STAND BY',
    },
    {
      title: 'Link In De Kabel',
      type: 'Website',
      vak: 'WEB III',
      body: 'Link in de Kabel vzw provides digital help and fun for vulnerable children and young people!. We have created a website for children to take up challenges. I worked alone at the backend and the admin panel.',
      technologies: ['PHP', 'Laravel', 'SQL'],
      team: 'Omar, Yannis, Nick, Jasper and myself.',
      picture: '',

      link: '',
      date: '2021 - 2022',
    },
  ];

  socials: Array<Object> = [
    {
      logo: 'linkedin.svg',
      title: 'LinkedIn',
      accountName: 'Ayoub Deneubourg',
      link: 'https://www.linkedin.com/in/ayoub-deneubourg-a615871a4/',
    },
    {
      logo: 'instagram.svg',
      title: 'Instagram',
      accountName: '@ayoub_dbr',
      link: 'https://www.instagram.com/ayoub_dbr',
    },
    {
      logo: 'facebook.svg',
      title: 'Facebook',
      accountName: 'Ayoub Deneubourg',
      link: 'https://www.facebook.com/ayoubdbr',
    },
    {
      logo: 'gmail.svg',
      title: 'Send a mail',
      accountName: 'deneubourgayoub@gmail.com',
      link: 'mailto:deneubourgayoub@gmail.com',
    },
  ];
  constructor() {}
}
